import React, { useState, useRef, RefObject } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { MdClose, MdFileUpload } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "components/card";
import * as Yup from "yup";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridRowId,
} from "@mui/x-data-grid";
import { useMenu } from "../menu/useMenu";
import { IKUpload, IKContext } from "imagekitio-react";
import {
  publicKey,
  urlEndpoint,
  authenticator,
} from "controller/common/imageKit/imageKit";

interface MenuProps {
  branchId: string;
}

interface FormValues {
  name: string;
  price: number;
  description: string;
  modifierIds: string[];
  category: string;
}

const Menu: React.FC<MenuProps> = ({ branchId }) => {
  const { onSubmit, loading } = useMenu();
  const [image, setImage] = useState<{ name: string; url: string } | null>(
    null
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [selectedModifiers, setSelectedModifiers] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const modifiers = [
    { id: "modifier1", label: "Pepsi", price: 40 },
    { id: "modifier2", label: "Salad", price: 10 },
    { id: "modifier3", label: "Ice Cream", price: 50 },
    { id: "modifier4", label: "French Fries", price: 50 },
  ];

  const categories = [
    { value: "fast_food", label: "Fast Food" },
    { value: "desserts", label: "Desserts" },
    { value: "beverages", label: "Beverages" },
  ];

  const fileInputRef: RefObject<HTMLInputElement> = useRef(null);

  const initialValues: FormValues = {
    name: "",
    price: 0,
    description: "",
    modifierIds: [],
    category: "",
  };

  // Handle modifier selection
  const handleModifierSelection = (selectionModel: GridRowSelectionModel) => {
    const selectedIds = selectionModel.map((id: GridRowId) => id.toString());
    setSelectedModifiers(selectedIds);
  };

  // Image upload success handler
  const onSuccess = (res: any) => {
    setImage({ name: res.fileName, url: res.url });
    setIsUploading(false);
  };

  // Image upload progress handler
  const onUploadProgress = () => {
    setIsUploading(true);
  };

  const onError = (error: any) => {
    console.error("Upload Error:", error);
    setIsUploading(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    price: Yup.number()
      .required("Price is required")
      .positive("Price must be positive"),
    description: Yup.string().nullable(),
    category: Yup.string().required("Category is required"),
    modifierIds: Yup.array().of(Yup.string()),
  });

  const columns: GridColDef[] = [
    { field: "label", headerName: "Modifier", flex: 1 },
    { field: "price", headerName: "Price", type: "number", flex: 1 },
  ];

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        {/* <Card extra={"w-full h-full p-3"}> */}
        <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
          <div className="px-5">
            <Formik<FormValues>
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                try {
                  await onSubmit({
                    ...values,
                    modifierIds: selectedModifiers,
                    branchId,
                    image: image ? image.url : null,
                  });

                  setSuccessMessage("Menu Submitted!");
                  resetForm();
                  setSelectedModifiers([]);
                  setImage(null);
                  setTimeout(() => setSuccessMessage(null), 3000);
                } catch (error) {
                  console.error("Error submitting menu:", error);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  {successMessage && (
                    <div className="mb-3 text-green-500">{successMessage}</div>
                  )}

                  {/* Name Field */}
                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Name*
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  {/* Price Field */}
                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Price*
                    </label>
                    <Field
                      type="number"
                      name="price"
                      parse={(value: string) => parseFloat(value)}
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    />
                    <ErrorMessage
                      name="price"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  {/* Category Field */}
                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Category*
                    </label>
                    <Field
                      as="select"
                      name="category"
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    >
                      <option value="" label="Select a category" />
                      {categories.map((category) => (
                        <option key={category.value} value={category.value}>
                          {category.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="category"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  {/* Image Upload Field */}
                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Image*
                    </label>
                    <div className="col-span-5 my-3">
                      <Card extra="w-full h-full p-3">
                        <div className="relative flex h-full w-full items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700">
                          {image && image.url ? (
                            <div className="relative h-[120px]">
                              <img
                                src={image.url}
                                alt={image.name}
                                className="h-[120px] w-[120px]"
                              />
                              <button
                                className="absolute right-1 top-1"
                                onClick={() => setImage(null)}
                              >
                                <MdClose className="h-6 w-6" />
                              </button>
                            </div>
                          ) : (
                            <button
                              type="button"
                              onClick={() => fileInputRef.current?.click()}
                              className="flex h-full w-full flex-col items-center justify-center"
                            >
                              {isUploading ? (
                                <CircularProgress className="h-[80px] w-[80px]" />
                              ) : (
                                <>
                                  <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                                  <h4 className="text-xl font-bold text-brand-500 dark:text-white">
                                    Upload Image
                                  </h4>
                                  <p className="mt-2 text-sm font-medium text-gray-600">
                                    {isUploading
                                      ? "Uploading"
                                      : "Click to Upload Image File"}
                                  </p>
                                </>
                              )}
                            </button>
                          )}
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                          />
                          <IKContext
                            publicKey={publicKey}
                            urlEndpoint={urlEndpoint}
                            authenticator={authenticator}
                          >
                            <IKUpload
                              onError={onError}
                              onSuccess={onSuccess}
                              onUploadStart={onUploadProgress}
                              folder={"/menu-images"}
                              accept="image/*"
                              style={{ display: "none" }}
                              ref={fileInputRef}
                            />
                          </IKContext>
                        </div>
                      </Card>
                    </div>
                  </div>

                  {/* Description Field */}
                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Description
                    </label>
                    <Field
                      as="textarea"
                      name="description"
                      rows="4"
                      className="mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  {/* Modifier Table */}
                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Modifiers
                    </label>
                    <div style={{ height: 300, width: "100%" }}>
                      <DataGrid
                        rows={modifiers}
                        columns={columns}
                        checkboxSelection
                        onRowSelectionModelChange={handleModifierSelection}
                      />
                    </div>
                  </div>

                  {/* Submit Button */}
                  <button
                    className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
                    type="submit"
                    disabled={isSubmitting || loading}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* </Card> */}
      </div>
    </div>
  );
};

export default Menu;
