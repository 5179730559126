import Card from "components/card";
import Switch from "components/switch";

const PageCard = ({
  data,
  handleToggleIos,
  handleToggleAndroid,
  maintenanceModeIOS,
  maintenanceModeAndroid,
}: any) => {
  return (
    <Card extra="!p-[20px]">
      <div className="flex flex-1 items-center justify-between gap-3">
        <label className="text-md mb-2 font-bold text-navy-700 dark:text-white">
          {data?.pageName}
        </label>
        <button
          className="linear mb-3 w-[60px] rounded-xl bg-brand-500 py-[6px] text-sm font-bold text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          type="submit"
        >
          {`Save`}
        </button>
      </div>
      <div className="flex flex-1 items-center gap-8">
        <div className="w-[150px]" />
        <label className="w-[40px] text-sm font-medium text-navy-700 dark:text-white">
          iOS
        </label>
        <label className="text-sm font-medium text-navy-700 dark:text-white">
          Android
        </label>
      </div>
      {data &&
        data?.sessions &&
        data?.sessions?.map((session: any) => (
          <div className="mb-2 mt-2 flex flex-1 items-center gap-8">
            <label className="w-[150px] text-sm font-medium text-navy-700 dark:text-white">
              {session?.name} :
            </label>
            <Switch
              checked={maintenanceModeIOS[session?.value]}
              onChange={() => handleToggleIos(session?.value)}
            />
            {!session?.hideOnAndroid && (
              <Switch
                checked={maintenanceModeAndroid[session?.value]}
                onChange={() => handleToggleAndroid(session?.value)}
              />
            )}
          </div>
        ))}
    </Card>
  );
};

export default PageCard;
