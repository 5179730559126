import React from "react";
import { Box, Skeleton } from "@mui/material";
import Card from "components/card";
import { useOneCustomer } from "model/queryCalls/queryCalls";

import { BsDatabaseFillX } from "react-icons/bs";
import { MdArrowCircleLeft, MdPerson } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const BasicDetails = ({ id }: any) => {
  const navigate = useNavigate();
  const { data: customerData, status } = useOneCustomer({ id });
  return (
    <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
      <Card extra={"w-full h-full p-3"}>
        {status === "loading" ? (
          <Box>
            <Skeleton sx={{ height: 190 }} />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        ) : status === "success" ? (
          <div className="relative mb-3 items-center justify-between py-5 text-center lg:px-10">
            <div className="mb-auto flex flex-col items-center justify-center">
              <div className="flex w-full flex-row items-center justify-between">
                <MdArrowCircleLeft
                  className="h-8 w-8 text-navy-700 hover:cursor-pointer hover:text-navy-500 dark:text-white hover:dark:text-gray-600"
                  onClick={() => navigate(-1)}
                />
              </div>
              <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[20px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                {customerData?.googlePhoto ? (
                  <img
                    src={customerData?.googlePhoto}
                    alt={customerData?.name}
                    className="h-[75px] w-[75px]"
                  />
                ) : (
                  <MdPerson />
                )}
              </div>
              <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                {customerData.name}
              </h4>
            </div>
            <Card extra={"w-full h-full p-3 mt-6"}>
              {/* Cards */}
              <div className="mt-3 grid grid-cols-1 gap-4 px-2 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Name</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {customerData?.name ? customerData?.name : "Not Available"}
                  </p>
                </div>

                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Mobile number</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {customerData?.mobileNumber
                      ? customerData?.mobileNumber
                      : "Not Available"}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Email</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {customerData?.email
                      ? customerData?.email
                      : "Not Available"}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Created At</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {customerData?.createdAt
                      ? customerData?.createdAt.toISOString()
                      : "Not Available"}
                  </p>
                </div>
                {/* <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
              <p className="text-sm text-gray-600">Last Login</p>
              <p className="text-base font-medium text-navy-700 dark:text-white">
                {customerData?.lastLoginAt ? customerData?.lastLoginAt.toISOString() : "Not Available"}
              </p>
            </div> */}
              </div>
            </Card>
          </div>
        ) : (
          <div className="flex w-full flex-col items-center justify-center">
            <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
            <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
          </div>
        )}
      </Card>
    </div>
  );
};

export default BasicDetails;
