import { Formik, Form, Field, FieldArray } from "formik";
import React from "react";
import { MdAdd } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import * as Yup from "yup";
import { useModifiers } from "./useModifiers";

interface AddModifierProps {
  branchId: string;
}

const validationSchema = Yup.object({
  items: Yup.array().of(
    Yup.object({
      name: Yup.string().required("Name is required"),
      price: Yup.string().required("Price is required"),
      description: Yup.string().required("Description is required"),
    })
  ),
});

const AddModifier: React.FC<AddModifierProps> = ({ branchId }) => {
  const { onSubmit, initialValues, deleteModifierFromDB, loading } =
    useModifiers(branchId);

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        {/* <Card extra={"w-full h-full p-3"}> */}
        <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
          <div className="px-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                console.log("Form Values:", values);
                values.items.forEach((item) => {
                  onSubmit({ ...item, branchId });
                });
              }}
            >
              {({ values }) => (
                <Form>
                  <FieldArray name="items">
                    {({ push, remove }) => (
                      <div>
                        {values.items.map((item, index) => (
                          <div
                            key={index}
                            className="mb-6 flex flex-wrap items-center space-x-4"
                          >
                            {/* Field for Modifier Name */}
                            <div className="flex-1">
                              <Field
                                name={`items[${index}].name`}
                                placeholder="Name*"
                                className="h-12 w-full rounded-xl border p-3 text-sm outline-none"
                              />
                            </div>

                            {/* Field for Modifier Price */}
                            <div className="flex-1">
                              <Field
                                name={`items[${index}].price`}
                                placeholder="Price"
                                className="h-12 w-full rounded-xl border p-3 text-sm outline-none"
                              />
                            </div>

                            {/* Field for Modifier Description */}
                            <div className="flex-1">
                              <Field
                                name={`items[${index}].description`}
                                placeholder="Description"
                                className="h-12 w-full rounded-xl border p-3 text-sm outline-none"
                              />
                            </div>

                            <div className="flex items-center">
                              <AiOutlineDelete
                                onClick={async () => {
                                  const modifierId = item._id;
                                  if (modifierId) {
                                    await deleteModifierFromDB(modifierId);
                                  }
                                  remove(index);
                                }}
                                className="cursor-pointer text-lg text-red-500"
                              />
                            </div>
                          </div>
                        ))}

                        {/* Buttons in the same row */}
                        <div className="mt-4 flex justify-between">
                          <button
                            type="button"
                            onClick={() =>
                              push({ name: "", price: "", description: "" })
                            }
                            className="flex items-center justify-center rounded-xl border-2 border-blue-500 px-4 py-2 text-blue-500 transition-colors hover:bg-blue-100"
                          >
                            Add Item
                            <MdAdd className="ml-2 text-lg" />
                          </button>

                          <button
                            type="submit"
                            className="rounded-xl bg-blue-500 px-5 py-2.5 text-white transition-colors hover:bg-blue-600"
                            disabled={loading}
                          >
                            {loading ? "Saving..." : "Submit"}
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* </Card> */}
      </div>
    </div>
  );
};

export default AddModifier;
