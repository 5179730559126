import { DeleteFAQ, addFAQ, updateFAQ } from "controller/services/FAQServices";
import React, { useContext } from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "controller/context/alertContext";
import { useFAQ, useOneFAQ } from "model/queryCalls/queryCalls";

export const useFAQs = () => {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [isActive, setIsActive] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { refetch } = useFAQ({ page, rowsPerPage });
  const [isVisible, setIsVisible] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();

  const { data: FAQData, isSuccess } = useOneFAQ({ id });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const initialValues: any = {
    question: "",
    answer: "",
    order: 1,
    isActive: true,
  };

  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Please enter question"),
    answer: Yup.string().required("Please enter answer"),
    order: Yup.number().required("Please add order"),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const params = {
        values,
        isActive,
      };
      if (id) {
        await updateFAQ({ id, ...params });
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "FAQ Updated successfully",
        });
      } else {
        await addFAQ(params);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "FAQ added successfully",
        });
      }

      setTimeout(() => {
        navigate("/admin/all-faqs");
      }, 2000);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: " Error while adding FAQ",
      });
    }
  };

  const onDelete = async (deleteId: any) => {
    try {
      await DeleteFAQ({ id: deleteId });
      refetch();
      setAlert({
        active: true,
        type: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "success",
        message: "Failed to delete",
      });
    }
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
    loading,
    isActive,
    setIsActive,
    id,
    isSuccess,
    FAQData,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
  };
};
