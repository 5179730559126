import Card from "components/card";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Switch from "components/switch";
import { useFAQs } from "./useFAQs";
import React from "react";

const AddFAQ = () => {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    loading,
    isActive,
    setIsActive,
    id,
    isSuccess,
    FAQData,
  } = useFAQs();

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <Card extra={"w-full h-full p-3"}>
          <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
            {/* <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              Notifications
            </h4> */}
            <div className="px-5">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setValues }) => {
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  React.useEffect(() => {
                    if (id && FAQData) {
                      setValues({
                        question: FAQData.question.en,
                        answer: FAQData.answer.en,
                        order: FAQData.order,
                      });
                      setIsActive(FAQData?.isActive);
                    }
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                  }, [id, isSuccess, FAQData]);
                  return (
                    <Form>
                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Question*
                        </label>
                        <Field
                          name="question"
                          type="textarea"
                          component="textarea"
                          as="textarea"
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          }
                        />
                        <ErrorMessage
                          name="question"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Answer*
                        </label>
                        <Field
                          type="textarea"
                          name="answer"
                          component="textarea"
                          as="textarea"
                          className={
                            "mt-2 flex h-24 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          }
                        />
                        <ErrorMessage
                          name="answer"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Order*
                        </label>
                        <Field
                          type="number"
                          name="order"
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          }
                          min="1"
                        />
                        <ErrorMessage
                          name="order"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <div className="mb-8 mt-6 flex flex-1 items-center gap-3">
                        <label
                          htmlFor="checkbox8"
                          className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                        >
                          Is Active*
                        </label>
                        <Switch
                          id="switch8"
                          checked={isActive}
                          onChange={() => setIsActive(!isActive)}
                        />
                      </div>

                      <button
                        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        // onClick={() => navigate("/auth")}
                        type="submit"
                      >
                        {loading ? `Saving...` : `Save`}
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddFAQ;
