import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "controller/context/alertContext";
import * as Yup from "yup";
import {
  addCuisine,
  deleteCuisine,
  updateCuisine,
} from "controller/services/cuisineServices";
import { useCuisinesList, useOneCuisine } from "model/queryCalls/queryCalls";

export const useCuisines = () => {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [cuisinesData, setCuisinesData] = useState({
    data: [],
    totalDocumentCount: 0,
  });
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    isActive: true,
  });

  // Image upload states
  const [cuisineImage, setCuisineImage] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const imageInputRef = React.useRef<HTMLInputElement>(null);

  // Handlers for image upload
  const onSuccess = (res: any) => {
    setCuisineImage(res.url);
    setIsUploading(false);
  };

  const onError = (error: any) => {
    console.error("Upload Error:", error);
    setIsUploading(false);
  };

  const onUploadProgress = () => {
    setIsUploading(true);
  };

  const handleDeleteImage = () => {
    setCuisineImage(null);
  };

  const navigate = useNavigate();
  const {
    data: fetchedCuisinesData,
    isSuccess,
    status,
  } = useCuisinesList({
    page,
    rowsPerPage,
  });

  useEffect(() => {
    if (isSuccess && fetchedCuisinesData) {
      setCuisinesData(fetchedCuisinesData);
    }
  }, [isSuccess, fetchedCuisinesData]);

  const { data: cuisineData, isLoading: isCuisineLoading } = useOneCuisine({
    id,
  });

  useEffect(() => {
    if (id && cuisineData) {
      setInitialValues({
        name: cuisineData.name || "",
        description: cuisineData.description || "",
        isActive:
          cuisineData.isActive !== undefined ? cuisineData.isActive : true,
      });
      setCuisineImage(cuisineData.image || null);
    }
  }, [id, cuisineData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const onDelete = async (deleteId: string) => {
    try {
      await deleteCuisine({ id: deleteId });

      const updatedCuisines = cuisinesData.data.filter(
        (cuisine) => cuisine._id !== deleteId
      );
      setCuisinesData({
        ...cuisinesData,
        data: updatedCuisines,
        totalDocumentCount: cuisinesData.totalDocumentCount - 1,
      });

      setAlert({
        active: true,
        type: "success",
        message: "Cuisine Deleted successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete",
      });
    }
  };

  const onToggleActive = async (cuisineId: string, newIsActive: boolean) => {
    try {
      await updateCuisine({
        id: cuisineId,
        data: { isActive: newIsActive },
      });

      const updatedCuisines = cuisinesData.data.map((cuisine) =>
        cuisine._id === cuisineId
          ? { ...cuisine, isActive: newIsActive }
          : cuisine
      );
      setCuisinesData({
        ...cuisinesData,
        data: updatedCuisines,
      });

      setAlert({
        active: true,
        type: "success",
        message: "Cuisine status updated successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to update cuisine status",
      });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter a cuisine name"),
    description: Yup.string().optional(),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const cuisineData = {
        ...values,
        image: cuisineImage,
      };

      if (id) {
        await updateCuisine({ id, data: cuisineData });
        setAlert({
          active: true,
          type: "success",
          message: "Cuisine Updated successfully",
        });
      } else {
        await addCuisine({ data: cuisineData });
        setAlert({
          active: true,
          type: "success",
          message: "Cuisine Created successfully",
        });
      }
      setTimeout(() => {
        navigate("/admin/all-cuisines");
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to save cuisine",
      });
    }
  };

  return {
    page,
    rowsPerPage,
    navigate,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    onToggleActive,
    cuisinesData,
    loading,
    setLoading,
    initialValues,
    setInitialValues,
    validationSchema,
    onSubmit,
    id,
    cuisineData,
    isSuccess,
    status,
    isCuisineLoading,
    // Image upload logic
    cuisineImage,
    isUploading,
    onSuccess,
    onError,
    onUploadProgress,
    handleDeleteImage,
    imageInputRef,
  };
};
