// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
import { useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { app } from "controller/services/dbServices";
import React from "react";
import { userRegister } from "controller/services/authServices";

export default function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
    password: Yup.string()
      .required("Please enter your password")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[@$!%*#?&]/,
        "Password must contain at least one special character (@, $, !, %, *, #, ?, &)"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
    name: Yup.string().required("Please enter your name"),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await app.emailPasswordAuth.registerUser({
        email: values.email,
        password: values.password,
      });
      await userRegister({ data: values });
      // loginSuccess();
      setTimeout(() => {
        navigate("/");
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.log('error =', error)
      // loginError();
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Create your account using email and password!
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="mb-3">
              <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                Email*
              </label>
              <Field
                type="email"
                name="email"
                className={
                  "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500"
              />
            </div>

            <div className="mb-3">
              <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                Name*
              </label>
              <Field
                type="name"
                name="name"
                className={
                  "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                }
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-red-500"
              />
            </div>

            {/* Password */}
            <div className="mb-3">
              <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                Password*
              </label>
              <Field
                type="password"
                name="password"
                className={
                  "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                }
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-500"
              />
            </div>
            <div className="mb-3">
              <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                Confirm Password*
              </label>
              <Field
                type="password"
                name="confirmPassword"
                className={
                  "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                }
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="text-red-500"
              />
            </div>
            {/* Checkbox */}
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              // onClick={() => navigate("/auth")}
              type="submit"
            >
              {loading ? `Creating...` : `Create Account`}
            </button>
          </Form>
        </Formik>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Already have an account?
          </span>
          <a
            href="/auth"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Sign in
          </a>
        </div>
      </div>
    </div>
  );
}
