import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { app } from "controller/services/dbServices";
import { UserContext } from "controller/context/userContext";
import { AlertContext } from "controller/context/alertContext";
import { Alert, Snackbar } from "@mui/material";
import { EmailResponse } from "./interface";
import { sendEmailAfterLogin } from "controller/services/authServices";

export default function SignIn() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { fetchUser, emailPasswordLogin, user, isVerified } =
    React.useContext(UserContext);
  const { setAlert, active, type, message } = React.useContext(AlertContext);

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
    password: Yup.string().required("Please enter your password"),
  });

  React.useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUser = async () => {
    if (!user) {
      const fetchedUser = await fetchUser();
      if (fetchedUser) {
        navigate("/admin");
      }
    }
  };

  const onSubmit = async (values: any) => {
    localStorage.setItem("isVerified", JSON.stringify(false));
    setLoading(true);
    try {
      const result = await emailPasswordLogin(values.email, values.password);
      if (result) {
        if (app.currentUser) {
          localStorage.setItem("authUser", JSON.stringify(app.currentUser));
          const isEmailSent = (await sendEmailAfterLogin({
            toEmail: values.email,
          })) as EmailResponse;
          if (isEmailSent?.status === 202 || isEmailSent?.status === 200) {
            setAlert({
              active: true,
              type: "success",
              message: "Email OTP sent to verify your account.",
            });
            // setTimeout(() => {
            //   setLoading(false);
            //   navigate("/auth/two-step-auth");
            // }, 1000);
            setTimeout(() => {
              if (isVerified === false) {
                navigate(`/auth/two-step-auth`);
              }
              if (isVerified === true) {
                navigate("/admin");
              }
            }, 1000);
          } else {
            setAlert({
              active: true,
              type: "error",
              message: "Email not sent. Please try again later.",
            });
            setLoading(false);
            navigate(`/`);
          }
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Login Failed!",
      });
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="mb-3">
              <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                Email*
              </label>
              <Field
                type="email"
                name="email"
                className={
                  "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500"
              />
            </div>

            {/* Password */}
            <div className="mb-3">
              <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                Password*
              </label>
              <Field
                type="password"
                name="password"
                className={
                  "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                }
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-500"
              />
            </div>
            {/* Checkbox */}
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              // onClick={() => navigate("/auth")}
              type="submit"
            >
              {loading ? "Loading..." : "Sign In"}
            </button>
          </Form>
        </Formik>

        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href="/auth/sign-up"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
          <div className="mt-4 text-center">
          <a
            href="/auth/forgot-password"
            className="ml-1 text-sm font-medium text-navy-700 hover:text-brand-600 dark:text-white "
          >
            Forgot password?
          </a>
        </div>
      </div>
      <Snackbar
        open={active}
        autoHideDuration={3000}
        onClose={() => {}}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
          severity={type}
          className="mx-3 my-3"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
