import React from "react";
import { ErrorMessage, Form, Field, Formik } from "formik";
import Card from "components/card";
import { MdClose, MdFileUpload } from "react-icons/md";
import { CircularProgress } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import envConfig from "../../env/env.json";
import Select from "react-select";
import { IKUpload, IKContext } from "imagekitio-react";
import {
  publicKey,
  urlEndpoint,
  authenticator,
} from "controller/common/imageKit/imageKit";
import {
  useCategoriesList,
  useCuisinesList,
} from "model/queryCalls/queryCalls";

interface Option {
  value: string;
  label: string;
}

interface RestaurantFormProps {
  initialValues: any;
  validationSchema: any;
  onSubmit: (values: any) => void;
  loading: boolean;
  options: Option[];
  brandImage: any;
  isUploading: boolean;
  bannerImage: any;
  isBannerUploading: boolean;
  onLogoSuccess: (res: any) => void;
  onBannerSuccess: (res: any) => void;
  onUploadProgress: () => void;
  onBannerUploadProgress: () => void;
  handleDeleteLogoFile: () => void;
  handleDeleteBannerFile: () => void;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  logoInputRef: React.RefObject<HTMLInputElement>;
  bannerInputRef: React.RefObject<HTMLInputElement>;
  location: { lat: number; lng: number } | null;
  mapCenter: { lat: number; lng: number };
  setLocation: (location: { lat: number; lng: number }) => void;
  handleMapClick: (event: google.maps.MapMouseEvent) => void;
  address: string;
  setAddress: (address: string) => void;
}

const mapContainerStyle = {
  height: "400px",
  width: "100%",
};

const RestaurantForm: React.FC<RestaurantFormProps> = ({
  initialValues,
  validationSchema,
  onSubmit,
  loading,
  options,
  brandImage,
  isUploading,
  bannerImage,
  isBannerUploading,
  onLogoSuccess,
  onBannerSuccess,
  onUploadProgress,
  onBannerUploadProgress,
  handleDeleteLogoFile,
  handleDeleteBannerFile,
  logoInputRef,
  bannerInputRef,
  location,
  mapCenter,
  setLocation,
  setAddress,
  address,
}) => {
  const { data: categoriesData } = useCategoriesList({});
  const { data: cuisinesData } = useCuisinesList({});

  const categoryOptions = categoriesData?.data?.map((category: any) => ({
    value: category._id,
    label: category.name,
  }));

  const cuisineOptions = cuisinesData?.data?.map((cuisine: any) => ({
    value: cuisine._id,
    label: cuisine.name,
  }));

  const getAddress = async (lat: number, lng: number) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${envConfig.MapsAPIKey}`
    );
    const data = await response.json();
    if (data.results.length > 0) {
      setAddress(data.results[0].formatted_address);
    } else {
      setAddress("Address not found");
    }
  };

  // Handle map click to set location and fetch the address
  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat() || 0;
    const lng = event.latLng?.lng() || 0;
    setLocation({ lat, lng });
    getAddress(lat, lng);
  };

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Handle image upload error
  const onError = (error: any) => {
    console.error("Upload Error:", error);
  };

  return (
    // <Card extra={"w-full h-full p-3"}>
    <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
      <div className="px-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              {/* Restaurant Name */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Restaurant Name*
                </label>
                <Field
                  type="name"
                  name="name"
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Description Field */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Description
                </label>
                <Field
                  as="textarea"
                  name="description"
                  rows="4"
                  className="mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Contact Details */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Contact Details*
                </label>
                <Field
                  type="text"
                  name="contactDetails"
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                />
                <ErrorMessage
                  name="contactDetails"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Username */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Username*
                </label>
                <Field
                  type="text"
                  name="username"
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Password */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Password*
                </label>
                <Field
                  type="password"
                  name="password"
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Payment Status */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Payment Status*
                </label>
                <Field
                  as="select"
                  name="paymentStatus"
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                >
                  <option value="">Select Payment Status</option>
                  <option value="Paid">Paid</option>
                  <option value="Unpaid">Unpaid</option>
                </Field>
                <ErrorMessage
                  name="paymentStatus"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Cuisine Multi-Select Dropdown */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Cuisine*
                </label>
                <Select
                  isMulti
                  options={cuisineOptions}
                  placeholder="Select cuisines"
                  name="cuisine"
                  className="mt-2"
                  onChange={(selectedOptions) =>
                    setFieldValue(
                      "cuisine",
                      selectedOptions
                        ? selectedOptions.map((option: Option) => option.value)
                        : []
                    )
                  }
                  value={cuisineOptions?.filter((option: Option) =>
                    values.cuisine?.includes(option.value)
                  )}
                />
                <ErrorMessage
                  name="cuisine"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Type */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Type
                </label>
                <Field
                  name="type"
                  as="select"
                  className="mt-2 flex h-12 w-full items-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                >
                  {options.map((option: Option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="type"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Location Field */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Location
                </label>
                <div className="flex items-center">
                  {location && (
                    <div className="ml-4 flex text-sm">
                      <div className="mr-4">Latitude: {location.lat}</div>
                      <div>Longitude: {location.lng}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Address Field */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Address
                </label>
                <div className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none">
                  {address || "Address will appear here"}
                </div>
              </div>

              {/* Google Map for selecting location */}
              <div className="mb-3">
                <LoadScript googleMapsApiKey={envConfig.MapsAPIKey}>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={mapCenter}
                    zoom={10}
                    onClick={handleMapClick}
                  >
                    {location && <Marker position={location} />}
                  </GoogleMap>
                </LoadScript>
              </div>

              <div className="flex flex-row items-center gap-10 sm:flex-row sm:gap-5">
                {/* Restaurant Logo Upload */}
                <div className="flex-column mb-3 mt-4 flex-1 items-center gap-3">
                  <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                    Restaurant Logo*
                  </label>
                  <Card className="grid h-full w-full grid-cols-1 gap-3 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none 2xl:grid-cols-11">
                    {brandImage ? (
                      <div className="relative flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0">
                        <img
                          src={brandImage}
                          alt="Restaurant logo"
                          className="h-[120px] w-[120px]"
                        />
                        <button
                          className="absolute right-1 top-1"
                          onClick={handleDeleteLogoFile}
                        >
                          <MdClose className="h-6 w-6" />
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => logoInputRef.current?.click()}
                        type="button"
                        className="flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0"
                      >
                        {isUploading ? (
                          <CircularProgress className="h-[80px] w-[80px]" />
                        ) : (
                          <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                        )}
                        <h4 className="text-xl font-bold text-brand-500 dark:text-white">
                          Upload Logo
                        </h4>
                      </button>
                    )}
                    <IKContext
                      publicKey={publicKey}
                      urlEndpoint={urlEndpoint}
                      authenticator={authenticator}
                    >
                      <IKUpload
                        onError={onError}
                        onSuccess={onLogoSuccess}
                        onUploadStart={onUploadProgress}
                        folder={"/restaurants"}
                        tags={["restaurant-uploads"]}
                        ref={logoInputRef}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                    </IKContext>
                  </Card>
                </div>

                {/* Restaurant Banner Upload */}
                <div className="flex-column mb-3 mt-4 flex-1 items-center gap-3">
                  <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                    Restaurant Banner*
                  </label>
                  <Card className="grid h-full w-full grid-cols-1 gap-3 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none 2xl:grid-cols-11">
                    {bannerImage ? (
                      <div className="relative flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0">
                        <img
                          src={bannerImage}
                          alt="Restaurant banner"
                          className="h-[120px] w-[120px]"
                        />
                        <button
                          className="absolute right-1 top-1"
                          onClick={handleDeleteBannerFile}
                        >
                          <MdClose className="h-6 w-6" />
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => bannerInputRef.current?.click()}
                        type="button"
                        className="flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0"
                      >
                        {isBannerUploading ? (
                          <CircularProgress className="h-[80px] w-[80px]" />
                        ) : (
                          <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                        )}
                        <h4 className="text-xl font-bold text-brand-500 dark:text-white">
                          Upload Banner
                        </h4>
                      </button>
                    )}
                    <IKContext
                      publicKey={publicKey}
                      urlEndpoint={urlEndpoint}
                      authenticator={authenticator}
                    >
                      <IKUpload
                        onError={onError}
                        onSuccess={onBannerSuccess}
                        onUploadStart={onBannerUploadProgress}
                        folder={"/restaurants"}
                        tags={["restaurant-uploads"]}
                        ref={bannerInputRef}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                    </IKContext>
                  </Card>
                </div>
              </div>

              {/* Category Field */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Category
                </label>
                <Select
                  isMulti
                  options={categoryOptions}
                  placeholder="Select category"
                  name="category"
                  className="mt-2"
                  onChange={(selectedOptions) =>
                    setFieldValue(
                      "category",
                      selectedOptions
                        ? selectedOptions.map((option: Option) => option.value)
                        : []
                    )
                  }
                  value={categoryOptions?.filter((option: Option) =>
                    values.category?.includes(option.value)
                  )}
                />
                <ErrorMessage
                  name="category"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Working Hours Section */}
              <div className="mb-6">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Working Hours
                </label>
                {daysOfWeek.map((day) => (
                  <div key={day} className="mb-3 flex items-center space-x-4">
                    <div className="w-1/4 text-sm font-semibold">{day}</div>

                    {/* Open Time Field */}
                    <div className="flex-1">
                      <label className="sr-only">Open Time</label>
                      <Field
                        type="time"
                        name={`workingHours.${day.toLowerCase()}.open`}
                        className="flex h-10 w-full rounded-lg border bg-white p-2 text-sm outline-none"
                        placeholder="Open"
                      />
                      <ErrorMessage
                        name={`workingHours.${day.toLowerCase()}.open`}
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <span className="text-sm font-medium">to</span>

                    {/* Close Time Field */}
                    <div className="flex-1">
                      <label className="sr-only">Close Time</label>
                      <Field
                        type="time"
                        name={`workingHours.${day.toLowerCase()}.close`}
                        className="flex h-10 w-full rounded-lg border bg-white p-2 text-sm outline-none"
                        placeholder="Close"
                      />
                      <ErrorMessage
                        name={`workingHours.${day.toLowerCase()}.close`}
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                ))}
              </div>

              <button
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                type="submit"
              >
                {loading ? `Saving...` : `Save`}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
    // </Card>
  );
};

export default RestaurantForm;
