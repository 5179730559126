import {
  DeletePopularRoute,
  addPopularRoute,
  calculateDistanceAndTime,
  updatePopularRoute,
} from "controller/services/popularRouteServices";
import React, { useContext } from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "controller/context/alertContext";
import {
  useOnePopularRoute,
  usePopularRoute,
} from "model/queryCalls/queryCalls";

export const useRoutes = () => {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [isActive, setIsActive] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { refetch } = usePopularRoute({ page, rowsPerPage });
  const [isVisible, setIsVisible] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();
  const [startPoint, setStartPoint] = React.useState<any>({});
  const [destination, setDestination] = React.useState<any>({});
  const [distance, setDistance] = React.useState<any>("");
  const [duration, setDuration] = React.useState<any>("");

  const { data: popularData, isSuccess } = useOnePopularRoute({ id });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const initialValues: any = {
    // question: "",
    // answer: "",
    order: 1,
    isActive: true,
  };

  React.useEffect(() => {
    if (startPoint && destination) {
      calcDistanceAndTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPoint, destination]);

  const calcDistanceAndTime = async () => {
    if (startPoint?.longitude && destination?.longitude) {
      try {
        // const originLatLng = {
        //   lat: id
        //     ? startPoint?.geometry.location.lat
        //     : startPoint?.geometry.location.lat(),
        //   lng: id
        //     ? startPoint?.geometry.location.lng
        //     : startPoint?.geometry.location.lng(),
        // };
        // const destinationLatLng = {
        //   lat: id
        //     ? destination?.geometry.location.lat
        //     : destination?.geometry.location.lat(),
        //   lng: id
        //     ? destination?.geometry.location.lng
        //     : destination?.geometry.location.lng(),
        // };

        const originLatLng = {
          lat: startPoint?.latitude,
          lng: startPoint?.longitude,
        };
        const destinationLatLng = {
          lat: destination?.latitude,
          lng: destination?.longitude,
        };

        const result: any = await calculateDistanceAndTime({
          originLatLng: originLatLng,
          destinationLatLng: destinationLatLng,
        });
        setDistance(result?.distance);
        setDuration(result?.duration);
      } catch (error) {
        console.error("Error fetching directions", error);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    order: Yup.number().required("Please add order"),
  });

  const onSubmit = async (values: any) => {
    if (
      !startPoint.longitude ||
      !destination.longitude ||
      !duration ||
      !distance
    )
      return;
    setLoading(true);
    try {
      const params = {
        values,
        isActive,
        startPoint,
        destination,
        duration,
        distance,
      };
      if (id) {
        await updatePopularRoute({ id, ...params });
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Popular route Updated successfully",
        });
      } else {
        await addPopularRoute(params);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Popular route added successfully",
        });
      }

      setTimeout(() => {
        navigate("/admin/popular-routes");
      }, 2000);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: " Error while adding Popular route",
      });
    }
  };

  const onDelete = async (deleteId: any) => {
    try {
      await DeletePopularRoute({ id: deleteId });
      refetch();
      setAlert({
        active: true,
        type: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "success",
        message: "Failed to delete",
      });
    }
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
    loading,
    isActive,
    setIsActive,
    id,
    isSuccess,
    popularData,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
    startPoint,
    setStartPoint,
    destination,
    setDestination,
    duration,
    setDuration,
    distance,
    setDistance,
  };
};
