import { useContext, useEffect, useState } from "react";
import { AlertContext } from "controller/context/alertContext";
import { useParams } from "react-router-dom";
import {
  addModifier,
  updateModifier,
  deleteModifier,
} from "controller/services/modifierServices";
import { useModifiersList } from "model/queryCalls/queryCalls";

interface Item {
  _id?: string;
  name: string;
  price: string;
  description: string;
}

interface InitialValues {
  items: Item[];
}

export const useModifiers = (branchId: any) => {
  console.log("branchid in usemodifier=====", branchId);
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<InitialValues>({
    items: [{ name: "", price: "", description: "" }],
  });
  const { data: fetchedModifiersData, isSuccess } = useModifiersList({
    page: 0,
    rowsPerPage: 10,
    branchId,
  });

  useEffect(() => {
    if (isSuccess && fetchedModifiersData) {
      setInitialValues({
        items: fetchedModifiersData.data.map((modifier: any) => ({
          _id: modifier._id || undefined,
          name: modifier.name,
          price: modifier.price,
          description: modifier.description,
        })),
      });
    }
  }, [isSuccess, fetchedModifiersData]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      if (id) {
        await updateModifier({ id, data: values });
        setAlert({
          active: true,
          type: "success",
          message: "Modifier Updated successfully",
        });
      } else {
        await addModifier({ data: values });
        setAlert({
          active: true,
          type: "success",
          message: "Modifier Created successfully",
        });
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to save modifier",
      });
    }
  };

  const deleteModifierFromDB = async (modifierId: string) => {
    setLoading(true);
    try {
      await deleteModifier({ id: modifierId });
      setAlert({
        active: true,
        type: "success",
        message: "Modifier Deleted successfully",
      });
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete modifier",
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    initialValues,
    onSubmit,
    deleteModifierFromDB,
    loading,
  };
};
