/*  */
/* FAQ CRUD services */
/*  */

import { app, getDBInstance, ObjectId } from "./dbServices";

export const addFAQ = async ({ values, isActive }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
  
    if (!values) {
      return;
    }
    const result = await getDBInstance()
      .collection("FAQs")
      .insertOne({
        question: {
          en: values.question,
        },
        answer: {
          en: values.answer,
        },
        isActive,
        dateCreated: new Date(),
        dateModified: null,
        order: values.order,
      });
    return result;
  };
  
  export const updateFAQ = async ({ id, values, isActive }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id || !values) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("FAQs")
      .updateOne(
        {
          _id: id,
        },
        {
          $set: {
            question: {
              en: values.question,
            },
            answer: {
              en: values.answer,
            },
            isActive,
            order: values.order,
            dateModified: new Date(),
          },
        }
      );
  
    return result;
  };
  
  export const DeleteFAQ = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("FAQs")
      .deleteOne({ _id: id });
  
    return result;
  };
  
  export const getFAQs = async ({ page = 0, rowsPerPage = 10 }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;
  
    const totalDocumentCount = await getDBInstance().collection("FAQs").count({});
  
    const result = await getDBInstance()
      .collection("FAQs")
      .aggregate([
        {
          $match: {},
        },
        {
          $sort: {
            order: 1,
          },
        },
        { $skip: skip },
        { $limit: limit },
        {
          $project: {
            _id: 1,
            question: 1,
            answer: 1,
            isActive: 1,
            dateCreated: 1,
            dateModified: 1,
            order: 1,
          },
        },
      ]);
  
    return { data: result, totalDocumentCount };
  };
  
  export const getOneFAQ = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("FAQs")
      .aggregate([
        {
          $match: { _id: id },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]);
  
    return result[0];
  };