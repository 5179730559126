import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { AlertContext } from "controller/context/alertContext";
import { Alert, Snackbar } from "@mui/material";
import { app } from "controller/services/dbServices";

export default function ForgotPassword() {
  const [loading, setLoading] = React.useState(false);
  const { setAlert, active, type, message } = React.useContext(AlertContext);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
  });

  const sendResetPassword = async (data: { email: string }) => {
    setLoading(true);
    try {
        await app.emailPasswordAuth.sendResetPasswordEmail({
            email: data.email,
          });
        setAlert({
          active: true,
          type: "success",
          message: "Email Sent Successfully",
          description: "We have sent a reset link to your email. Please check your inbox and follow the instructions to reset your password."
        });
        setLoading(false);
    } catch (error: any) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to Send Email",
        description: "There was an issue sending the reset link to your email. Please try again later or contact support for assistance."
      });
      setLoading(false);
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-3xl font-bold text-navy-700 dark:text-white">
          Forgot Password?
        </h4>
        <p className="mb-5 ml-1 text-sm text-gray-600">
          Please provide the email address associated with your account, and we
          will send you an email containing a link to reset your password.
          Clicking on the provided link will redirect you to the password
          resetting process.
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={sendResetPassword}
        >
          <Form>
            <div className="mb-3">
              <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                Email Address *
              </label>
              <Field
                type="text"
                name="email"
                className={
                  "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500"
              />
            </div>
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              type="submit"
            >
              {loading ? "Loading..." : "Send Reset Link"}
            </button>
          </Form>
        </Formik>
         <div className="mt-4 text-center">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Return to?
          </span>
          <a
            href="/auth/sign-in"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Sign In
          </a>
        </div>
      </div>
      <Snackbar
        open={active}
        autoHideDuration={3000}
        onClose={() => {}}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
          severity={type}
          className="mx-3 my-3"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
