/*  */
/* Popular Routes CRUD services */
/*  */

import { app, getDBInstance, ObjectId } from "./dbServices";

export const addPopularRoute = async ({
  values,
  isActive,
  startPoint,
  destination,
  duration,
  distance,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!values) {
    return;
  }
  const result = await getDBInstance().collection("routes").insertOne({
    startPoint,
    destination,
    isActive,
    duration,
    distance,
    type: "popularRoute",
    order: values.order,
    dateCreated: new Date(),
    dateModified: null,
    stops: [],
  });
  return result;
};

export const updatePopularRoute = async ({
  id,
  values,
  isActive,
  startPoint,
  destination,
  duration,
  distance,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !values) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("routes")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          startPoint,
          destination,
          duration,
          distance,
          isActive,
          type: "popularRoute",
          order: values.order,
          dateModified: new Date(),
          stops: [],
        },
      }
    );

  return result;
};

export const DeletePopularRoute = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("routes")
    .deleteOne({ _id: id });

  return result;
};

export const getPopularRoutes = async ({ page = 0, rowsPerPage = 10 }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance()
    .collection("routes")
    .count({});

  const result = await getDBInstance()
    .collection("routes")
    .aggregate([
      {
        $match: { type: "popularRoute" },
      },
      {
        $sort: {
          order: 1,
        },
      },
      { $skip: skip },
      { $limit: limit },
      {
        $project: {
          _id: 1,
          startPoint: 1,
          destination: 1,
          isActive: 1,
          dateCreated: 1,
          dateModified: 1,
          order: 1,
          duration: 1,
          distance: 1,
        },
      },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOnePopularRoute = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("routes")
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};

export const calculateDistanceAndTime = async ({
  originLatLng,
  destinationLatLng,
}: any) => {
  if (!originLatLng || !destinationLatLng) {
    return;
  }

  const origin = `${originLatLng.lat},${originLatLng.lng}`;
  const destination = `${destinationLatLng.lat},${destinationLatLng.lng}`;

  const result = await app.currentUser.callFunction(
    "calculateDistanceAndTime",
    {
      origin,
      destination,
    }
  );
  return result;
};
