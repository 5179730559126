import Card from "components/card";
import { useMaintenanceMode } from "./useMaintenance";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import Switch from "components/switch";
import PageCard from "./pageCard";
import { Box, Skeleton } from "@mui/material";
import { BsDatabaseFillX } from "react-icons/bs";

const MaintenanceMode = () => {
  const {
    maintenanceData,
    status,
    initialValues,
    validationSchema,
    onSubmit,
    loading,
    fullAndroidMaintenance,
    setFullAndroidMaintenance,
    fullIOSMaintenance,
    setFullIOSMaintenance,
    maintenanceModeAndroid,
    maintenanceModeIOS,
    handleToggleAndroid,
    handleToggleIos,
    maintenancePageData,
  } = useMaintenanceMode();

  if (status === "loading") {
    return (
      <div className="flex w-full flex-col gap-5">
        <Card extra={"w-full h-full p-3"}>
          <Box>
            <Skeleton sx={{ height: 190 }} />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        </Card>
      </div>
    );
  }
  if (status === "success") {
    return (
      <div className="flex w-full flex-col gap-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setValues }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            React.useEffect(() => {
              if (maintenanceData && maintenanceData?._id) {
                setValues({
                  androidVersion: maintenanceData.androidMinimumVersion,
                  iOSVersion: maintenanceData.iosMinimumVersion,
                });
              }
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [maintenanceData]);
            return (
              <Form>
                <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
                  <Card extra="!p-[20px]">
                    <div className="flex flex-1 items-center justify-between gap-3">
                      <label className="text-md font-bold text-navy-700 dark:text-white">
                        App Minimum Version
                      </label>
                      <button
                        className="linear mb-3 w-[60px] rounded-xl bg-brand-500 py-[6px] text-sm font-bold text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        type="submit"
                      >
                        {`Save`}
                      </button>
                    </div>
                    <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2">
                      <div>
                        <label className="text-sm font-medium text-navy-700 dark:text-white">
                          Android Minimum Version*
                        </label>
                        <Field
                          type="text"
                          name="androidVersion"
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          }
                          placeholder="x.x.x"
                        />
                        <ErrorMessage
                          name="androidVersion"
                          component="div"
                          className="font-regular text-sm text-red-500"
                        />
                      </div>
                      <div>
                        <label className="text-sm font-medium text-navy-700 dark:text-white">
                          iOS Minimum Version*
                        </label>
                        <Field
                          type="text"
                          name="iOSVersion"
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          }
                          placeholder="x.x.x"
                        />
                        <ErrorMessage
                          name="iOSVersion"
                          component="div"
                          className="font-regular text-sm text-red-500"
                        />
                      </div>
                    </div>
                  </Card>
                  <Card extra="!p-[20px]">
                    <div className="flex flex-1 items-center justify-between gap-3">
                      <label className="text-md mb-2 font-bold text-navy-700 dark:text-white">
                        Full App Maintenance Mode
                      </label>
                      <button
                        className="linear mb-3 w-[60px] rounded-xl bg-brand-500 py-[6px] text-sm font-bold text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        type="submit"
                      >
                        {`Save`}
                      </button>
                    </div>
                    {/* <div className="mb-2 mt-2 flex flex-1 items-center gap-3">
                      <label className="w-[150px] text-sm font-medium text-navy-700 dark:text-white">
                        Both Platforms:
                      </label>
                      <Switch
                        id="both"
                        checked={fullAppMaintenance}
                        onChange={() =>
                          setFullAppMaintenance(!fullAppMaintenance)
                        }
                      />
                    </div> */}
                    <div className="mb-2 mt-2 flex flex-1 items-center gap-3">
                      <label className="w-[150px] text-sm font-medium text-navy-700 dark:text-white">
                        Android App:
                      </label>
                      <Switch
                        id="android"
                        checked={fullAndroidMaintenance}
                        onChange={() =>
                          setFullAndroidMaintenance(!fullAndroidMaintenance)
                        }
                      />
                    </div>
                    <div className="mb-2 mt-2 flex flex-1 items-center gap-3">
                      <label className="w-[150px] text-sm font-medium text-navy-700 dark:text-white">
                        iOS App:
                      </label>
                      <Switch
                        id="ios"
                        checked={fullIOSMaintenance}
                        onChange={() =>
                          setFullIOSMaintenance(!fullIOSMaintenance)
                        }
                      />
                    </div>
                  </Card>
                </div>
                <div className="mb-5 mt-5 grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
                  <PageCard
                    data={maintenancePageData.login}
                    handleToggleIos={handleToggleIos}
                    handleToggleAndroid={handleToggleAndroid}
                    maintenanceModeIOS={maintenanceModeIOS}
                    maintenanceModeAndroid={maintenanceModeAndroid}
                  />
                  <PageCard
                    data={maintenancePageData.home}
                    handleToggleIos={handleToggleIos}
                    handleToggleAndroid={handleToggleAndroid}
                    maintenanceModeIOS={maintenanceModeIOS}
                    maintenanceModeAndroid={maintenanceModeAndroid}
                  />
                  <PageCard
                    data={maintenancePageData.stationDetails}
                    handleToggleIos={handleToggleIos}
                    handleToggleAndroid={handleToggleAndroid}
                    maintenanceModeIOS={maintenanceModeIOS}
                    maintenanceModeAndroid={maintenanceModeAndroid}
                  />
                  <PageCard
                    data={maintenancePageData.tripPlanner}
                    handleToggleIos={handleToggleIos}
                    handleToggleAndroid={handleToggleAndroid}
                    maintenanceModeIOS={maintenanceModeIOS}
                    maintenanceModeAndroid={maintenanceModeAndroid}
                  />
                  <PageCard
                    data={maintenancePageData.askAi}
                    handleToggleIos={handleToggleIos}
                    handleToggleAndroid={handleToggleAndroid}
                    maintenanceModeIOS={maintenanceModeIOS}
                    maintenanceModeAndroid={maintenanceModeAndroid}
                  />
                  <PageCard
                    data={maintenancePageData.alertMe}
                    handleToggleIos={handleToggleIos}
                    handleToggleAndroid={handleToggleAndroid}
                    maintenanceModeIOS={maintenanceModeIOS}
                    maintenanceModeAndroid={maintenanceModeAndroid}
                  />
                </div>

                <Card extra="!p-[20px] mb-5">
                  <div className="flex flex-1 items-center justify-between gap-3">
                    <label className="text-md mb-2 font-bold text-navy-700 dark:text-white">
                      Settings
                    </label>
                    <button
                      className="linear mb-3 w-[60px] rounded-xl bg-brand-500 py-[6px] text-sm font-bold text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      type="submit"
                    >
                      {`Save`}
                    </button>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="flex flex-1 items-center gap-8">
                      <div className="w-[150px]" />
                      <label className="w-[40px] text-sm font-medium text-navy-700 dark:text-white">
                        iOS
                      </label>
                      <label className="text-sm font-medium text-navy-700 dark:text-white">
                        Android
                      </label>
                    </div>
                    <div className="flex hidden flex-1 items-center gap-8 lg:flex">
                      <div className="w-[150px]" />
                      <label className="w-[40px] text-sm font-medium text-navy-700 dark:text-white">
                        iOS
                      </label>
                      <label className="text-sm font-medium text-navy-700 dark:text-white">
                        Android
                      </label>
                    </div>

                    {maintenancePageData &&
                      maintenancePageData?.settings &&
                      maintenancePageData?.settings?.sessions &&
                      maintenancePageData?.settings?.sessions.map(
                        (session: any) => (
                          <div className="mb-2 mt-2 flex flex-1 items-center gap-8">
                            <label className="w-[150px] text-sm font-medium text-navy-700 dark:text-white">
                              {session.name}:
                            </label>
                            <Switch
                              checked={maintenanceModeIOS[session.value]}
                              onChange={() => handleToggleIos(session.value)}
                            />
                            <Switch
                              checked={maintenanceModeAndroid[session.value]}
                              onChange={() =>
                                handleToggleAndroid(session.value)
                              }
                            />
                          </div>
                        )
                      )}
                  </div>
                </Card>
                <button
                  className="linear mb-3 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  type="submit"
                >
                  {loading ? `Saving...` : `Save`}
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
  if (status === "error") {
    return (
      <div className="flex w-full flex-col gap-5">
        <Card extra={"w-full h-full p-6 py-10 mt-4"}>
          <div className="flex w-full flex-col items-center justify-center">
            <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
            <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
          </div>
        </Card>
      </div>
    );
  }
};

export default MaintenanceMode;
