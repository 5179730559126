/*  */
/* CMS services */
/*  */

import { app, getDBInstance, ObjectId } from "./dbServices";

export const AddCms = async ({ values, isActive, cmsData }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
  
    if (!values || !cmsData) {
      return;
    }
  
    const slug = await generateSlug({ title: values.pageName });
  
    const result = await getDBInstance()
      .collection("cmsPages")
      .insertOne({
        pageName: { en: values.pageName },
        slug,
        content: { en: cmsData },
        isActive,
        dateCreated: new Date(),
        dateModified: null,
      });
    return result;
  };
  
  export const UpdateCms = async ({ id, values, isActive, cmsData }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id || !values || !cmsData) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const slug = await generateSlug({ title: values.pageName });
    const result = await getDBInstance()
      .collection("cmsPages")
      .updateOne(
        {
          _id: id,
        },
        {
          $set: {
            pageName: { en: values.pageName },
            slug,
            content: { en: cmsData },
            isActive,
            dateModified: new Date(),
          },
        }
      );
  
    return result;
  };
  
  export const getCms = async ({ page = 0, rowsPerPage = 10 }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;
  
    const totalDocumentCount = await getDBInstance()
      .collection("cmsPages")
      .count({});
  
    const result = await getDBInstance()
      .collection("cmsPages")
      .aggregate([
        {
          $match: {},
        },
        {
          $sort: {
            _id: -1,
          },
        },
        { $skip: skip },
        { $limit: limit },
      ]);
  
    return { data: result, totalDocumentCount };
  };
  
  export const getOneCms = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (!id) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("cmsPages")
      .aggregate([
        {
          $match: { _id: id },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]);
  
    return result[0];
  };
  
  export const DeleteCms = async ({ id }: any) => {
    if (!app || !app.currentUser) {
      return;
    }
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance()
      .collection("cmsPages")
      .deleteOne({ _id: id });
  
    return result;
  };
  export const generateSlug = ({ title }: any) => {
    // Convert to lowercase
    return (
      title
        // Convert to lowercase
        .toLowerCase()
        // Replace spaces with hyphens
        .replace(/\s+/g, "-")
        // Remove all non-word chars (keep only letters, numbers, and hyphens)
        .replace(/[^a-z0-9-]/g, "")
        // Replace multiple hyphens with a single hyphen
        .replace(/-+/g, "-")
    );
  };