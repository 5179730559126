import { useQuery } from "react-query";
import { useContext } from "react";
import { UserContext } from "controller/context/userContext";
import { getFAQs, getOneFAQ } from "controller/services/FAQServices";
import {
  getOnePopularRoute,
  getPopularRoutes,
} from "controller/services/popularRouteServices";
import { getAppSettings } from "controller/services/maintenanceServices";
import {
  getCustomers,
  getOneCustomer,
  getOneUser,
  getUsers,
} from "controller/services/userServices";
import { getCms, getOneCms } from "controller/services/CMSServices";
import {
  getCategories,
  getOneCategory,
} from "controller/services/categoryServices";
import {
  getModifiers,
  getOneModifier,
} from "controller/services/modifierServices";
import {
  getRestaurantValues,
  getOneRestaurant,
} from "controller/services/restaurantServices";
import {
  getCuisines,
  getOneCuisine,
} from "controller/services/cuisineServices";
import { getOrders } from "controller/services/orderServices";
import {
  GetOneMenuValue,
  GetMenuValues,
} from "controller/services/menuServices";

export const useFAQ = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["FAQsData", user, page, rowsPerPage], () =>
    getFAQs({ page, rowsPerPage })
  );
};

export const useOneFAQ = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneFAQ", user, { id }], () => getOneFAQ({ id }));
};

export const usePopularRoute = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["popularRoute", user, page, rowsPerPage], () =>
    getPopularRoutes({ page, rowsPerPage })
  );
};

export const useOnePopularRoute = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["onePopularRoute", user, { id }], () =>
    getOnePopularRoute({ id })
  );
};

export const useAppSettings = ({ type }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["appSettings", user, { type }], () =>
    getAppSettings({ type })
  );
};

//users
export const useUsersList = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["usersData", user, page, rowsPerPage], () =>
    getUsers({ page, rowsPerPage })
  );
};

export const useOneUser = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneUser", user, { id }], () => getOneUser({ id }));
};

export const useCustomers = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["customerData", user, page, rowsPerPage], () =>
    getCustomers({ page, rowsPerPage })
  );
};

export const useOneCustomer = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneCustomer", user, { id }], () => getOneCustomer({ id }));
};

export const useCms = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["cmsData", user, page, rowsPerPage], () =>
    getCms({ page, rowsPerPage })
  );
};

export const useOneCms = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["onePoster", user, { id }], () => getOneCms({ id }));
};

export const useRestaurantValues = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["restaurantData", user, page, rowsPerPage], () =>
    getRestaurantValues({ page, rowsPerPage })
  );
};

export const useOneRestaurant = ({ id }: any) => {
  console.log("restaurant id in usequery=======", id);
  const { user } = useContext(UserContext);
  return useQuery(["oneRestaurant", user, { id }], () =>
    getOneRestaurant({ id })
  );
};

export const useCategoriesList = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["categoriesData", user, page, rowsPerPage], () =>
    getCategories({ page, rowsPerPage })
  );
};

export const useOneCategory = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneCategory", user, { id }], () => getOneCategory({ id }));
};

export const useModifiersList = ({ page, rowsPerPage, branchId }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["modifiersData", user, page, rowsPerPage, branchId], () =>
    getModifiers({ page, rowsPerPage, branchId })
  );
};

export const useOneModifier = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneModifier", user, { id }], () => getOneModifier({ id }));
};

export const useCuisinesList = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["cuisinesData", user, page, rowsPerPage], () =>
    getCuisines({ page, rowsPerPage })
  );
};

export const useOneCuisine = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneCuisine", user, { id }], () => getOneCuisine({ id }));
};

export const useOrdersList = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["ordersData", user, page, rowsPerPage], () =>
    getOrders({ page, rowsPerPage })
  );
};

export const useMenuList = ({ page, rowsPerPage }: any) => {
  return useQuery(
    ["menuList", page, rowsPerPage],
    () => GetMenuValues({ page, rowsPerPage }),
    {
      keepPreviousData: true,
    }
  );
};

export const useOneMenu = ({ id }: any) => {
  return useQuery(["oneMenu", { id }], () => GetOneMenuValue({ id }), {
    enabled: !!id,
  });
};
