import { app, getDBInstance, ObjectId } from "./dbServices";

export const AddMenuValues = async ({ data }: any) => {
  console.log("values", data);

  if (!app || !app.currentUser) {
    return;
  }

  // Validate the input data
  if (!data) {
    return;
  }

  // Insert a new menu item into the "menu" collection
  const result = await getDBInstance()
    .collection("menu")
    .insertOne({
      branchId: data.branchId,
      name: { en: data.name },
      price: data.price,
      category: data.category,
      description: data.description || "",
      modifier: data.modifier || [],
      image: data.image || null,
      createdAt: new Date(),
      createdBy: data.createdBy || app.currentUser.id,
      modifiedAt: null,
      modifiedBy: null,
    });

  return result;
};

export const GetMenuValues = async ({ page = 0, rowsPerPage = 10 }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  const skip = page * rowsPerPage;
  const limit = rowsPerPage;
  const totalDocumentCount = await getDBInstance().collection("menu").count({});

  const result = await getDBInstance()
    .collection("menu")
    .aggregate([{ $skip: skip }, { $limit: limit }]);

  return { data: result, totalDocumentCount };
};

export const GetOneMenuValue = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!id) {
    return;
  }

  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection("menu").findOne({
    _id: id,
  });

  return result;
};
