import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "components/card";
import { useRestaurant } from "./useRestaurant";
import AddModifier from "../modifiers/addModifier";
import RestaurantForm from "../../../components/RestaurantForm/index";
import Orders from "../../../components/Orders/index";
import Menu from "components/menu/Menu";

const AddRestaurant = () => {
  const { id } = useParams<{ id?: string }>();
  const {
    loading,
    initialValues,
    validationSchema,
    onSubmit,
    options,
    brandImage,
    isUploading,
    bannerImage,
    isBannerUploading,
    onLogoSuccess,
    onBannerSuccess,
    onUploadProgress,
    onBannerUploadProgress,
    handleDeleteLogoFile,
    handleDeleteBannerFile,
    logoInputRef,
    bannerInputRef,
    isActive,
    setIsActive,
    location,
    setLocation,
    branchId,
    address,
    setAddress,
    setRestaurantId,
  } = useRestaurant();

  React.useEffect(() => {
    setRestaurantId(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [mapCenter] = useState({ lat: 10.8505, lng: 76.2711 });

  const steps = ["Add Restaurant", "Add-On", "Menu", "Orders"];

  const isStepSkipped = (step: number) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleReset = () => setActiveStep(0);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const { latLng } = event;
    const newLocation = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    setLocation(newLocation);
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <RestaurantForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            loading={loading}
            options={options}
            brandImage={brandImage}
            bannerImage={bannerImage}
            isUploading={isUploading}
            isBannerUploading={isBannerUploading}
            onLogoSuccess={onLogoSuccess}
            onBannerSuccess={onBannerSuccess}
            onUploadProgress={onUploadProgress}
            onBannerUploadProgress={onBannerUploadProgress}
            handleDeleteLogoFile={handleDeleteLogoFile}
            handleDeleteBannerFile={handleDeleteBannerFile}
            isActive={isActive}
            setIsActive={setIsActive}
            logoInputRef={logoInputRef}
            bannerInputRef={bannerInputRef}
            location={location}
            setAddress={setAddress}
            address={address}
            mapCenter={mapCenter}
            setLocation={setLocation}
            handleMapClick={handleMapClick}
          />
        );
      case 1:
        return <AddModifier branchId={branchId} />;
      case 2:
        return <Menu branchId={branchId} />;
      case 3:
        return <Orders />;
      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <Box sx={{ width: "100%", mb: 10 }}>
      <Stepper activeStep={activeStep} sx={{ mt: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you're finished
          </Typography>
          <Button onClick={handleReset}>Reset</Button>
        </>
      ) : (
        <>
          <Card extra={"w-full h-full mt-4 mb-4"}>
            <Box sx={{ mt: 2, mb: 1 }}>{renderStepContent(activeStep)}</Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 6 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </Card>
        </>
      )}
    </Box>
  );
};

export default AddRestaurant;
