import Card from "components/card";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Switch from "components/switch";
import { useRoutes } from "./useRoutes";
import React from "react";
import Autocomplete from "react-google-autocomplete";

const AddRoute = () => {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    loading,
    isActive,
    setIsActive,
    id,
    isSuccess,
    popularData,
    startPoint,
    setStartPoint,
    destination,
    setDestination,
    duration,
    setDuration,
    distance,
    setDistance,
  } = useRoutes();

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <Card extra={"w-full h-full p-3"}>
          <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
            <div className="px-5">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setValues }) => {
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  React.useEffect(() => {
                    if (id && popularData) {
                      setValues({
                        // question: FAQData.question.en,
                        // answer: FAQData.answer.en,
                        order: popularData.order,
                      });
                      setIsActive(popularData?.isActive);
                      setStartPoint(popularData?.startPoint);
                      setDestination(popularData?.destination);
                      setDuration(popularData?.duration);
                      setDistance(popularData?.distance);
                    }
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                  }, [id, isSuccess, popularData]);
                  return (
                    <Form>
                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Starting Point*
                        </label>

                        <Autocomplete
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          }
                          apiKey={"AIzaSyDRSFRWK-McsRxCweiFib7HgfdREV5nGDE"}
                          onPlaceSelected={(place: any) => {
                            const location = {
                              id: place?.place_id,
                              name: place?.formatted_address,
                              latitude: place?.geometry.location.lat(),
                              longitude: place?.geometry.location.lng(),
                            };
                            setStartPoint(location);
                          }}
                          options={{
                            types: ["(regions)"],
                            componentRestrictions: { country: "us" },
                          }}
                          value={startPoint?.name}
                          onChange={(e) => setStartPoint(e)}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Destination*
                        </label>

                        <Autocomplete
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          }
                          apiKey={"AIzaSyDRSFRWK-McsRxCweiFib7HgfdREV5nGDE"}
                          onPlaceSelected={(place: any) => {
                            const location = {
                              id: place?.place_id,
                              name: place?.formatted_address,
                              latitude: place?.geometry.location.lat(),
                              longitude: place?.geometry.location.lng(),
                            };
                            setDestination(location);
                          }}
                          options={{
                            types: ["(regions)"],
                            componentRestrictions: { country: "us" },
                          }}
                          value={destination?.name}
                          onChange={(e) => setDestination(e)}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Estimated Distance
                        </label>
                        <input
                          type="text"
                          // name="order"
                          placeholder="Estimated distance will be calculated"
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-gray-200 p-3 text-sm text-gray-700 outline-none"
                          }
                          // min="1"
                          value={distance}
                          disabled={true}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Estimated Time
                        </label>
                        <input
                          type="text"
                          // name="order"
                          placeholder="Estimated time will be calculated"
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-gray-200 p-3 text-sm text-gray-700 outline-none"
                          }
                          // min="1"
                          value={duration}
                          disabled={true}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Order*
                        </label>
                        <Field
                          type="number"
                          name="order"
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          }
                          min="1"
                        />
                        <ErrorMessage
                          name="order"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <div className="mb-8 mt-6 flex flex-1 items-center gap-3">
                        <label
                          htmlFor="checkbox8"
                          className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                        >
                          Is Active*
                        </label>
                        <Switch
                          id="switch8"
                          checked={isActive}
                          onChange={() => setIsActive(!isActive)}
                        />
                      </div>

                      <button
                        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        // onClick={() => navigate("/auth")}
                        type="submit"
                      >
                        {loading ? `Saving...` : `Save`}
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddRoute;
