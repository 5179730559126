import { app, getDBInstance, ObjectId } from "./dbServices";

export const AddRestaurantValues = async ({ data, location, address }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!data) {
    return;
  }

  const result = await getDBInstance()
    .collection("restaurant")
    .insertOne({
      name: { en: data.name },
      description: data.description,
      createdAt: new Date(),
      createdBy: data.createdBy || app.currentUser.id,
      modifiedAt: null,
      modifiedBy: null,
    });

  const branchResult = await getDBInstance()
    .collection("branch")
    .insertOne({
      restaurantId: result.insertedId,
      workingHours: data.workingHours,
      isActive: data.isActive,
      type: data.type,
      category: data.category,
      contactDetails: data.contactDetails,
      username: data.username,
      password: data.password,
      paymentStatus: data.paymentStatus,
      cuisine: data.cuisine,
      logo: data.logo,
      banner: data.banner,
      location: {
        coordinates: location,
        address: address,
      },
      createdAt: new Date(),
      createdBy: data.createdBy || app.currentUser.id,
      modifiedAt: null,
      modifiedBy: null,
    });

  return branchResult;
};

export const getRestaurantValues = async ({
  page = 0,
  rowsPerPage = 10,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance()
    .collection("restaurant")
    .count({});

  const result = await getDBInstance()
    .collection("restaurant")
    .aggregate([
      {
        $match: {},
      },
      {
        $lookup: {
          from: "branch",
          localField: "_id",
          foreignField: "restaurantId",
          as: "branchDetails",
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
      { $skip: skip },
      { $limit: limit },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOneRestaurant = async ({ id }: any) => {
  try {
    const restaurantObjectId = new ObjectId(id);

    const result = await getDBInstance()
      .collection("restaurant")
      .aggregate([
        {
          $match: {
            _id: restaurantObjectId,
          },
        },
        {
          $lookup: {
            from: "branch",
            localField: "_id",
            foreignField: "restaurantId",
            as: "branchDetails",
          },
        },
        {
          $unwind: {
            path: "$branchDetails",
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $lookup: {
            from: "modifiers",
            localField: "branchDetails._id",
            foreignField: "branchId",
            as: "branchDetails.modifiers",
          },
        },
        {
          $lookup: {
            from: "menu",
            localField: "branchDetails._id",
            foreignField: "branchId",
            as: "branchDetails.menuItems",
          },
        },
        {
          $group: {
            _id: "$_id",
            name: { $first: "$name" },
            description: { $first: "$description" },
            createdAt: { $first: "$createdAt" },
            createdBy: { $first: "$createdBy" },
            modifiedAt: { $first: "$modifiedAt" },
            modifiedBy: { $first: "$modifiedBy" },
            branchDetails: { $push: "$branchDetails" },
          },
        },
      ]);
    console.log("Result of getOneRestaurant in restaurant service:", result);
    return result.length ? result[0] : null;
  } catch (error) {
    console.error("Error fetching restaurant:", error);
    return null;
  }
};

// Delete restaurant
export const deleteRestaurant = async (restaurantId: string) => {
  if (!app || !app.currentUser) {
    return;
  }

  const db = getDBInstance();
  const restaurantObjectId = new ObjectId(restaurantId);

  // Step 1: Delete the restaurant document
  const restaurantDeleteResult = await db.collection("restaurant").deleteOne({
    _id: restaurantObjectId,
  });

  // Step 2: Delete the related branch documents and get the list of deleted branch IDs
  const branches = await db.collection("branch").find({
    restaurantId: restaurantObjectId,
  });

  const branchIds = branches.map((branch) => branch._id);

  const branchDeleteResult = await db.collection("branch").deleteMany({
    restaurantId: restaurantObjectId,
  });

  // Step 3: Delete the modifiers related to the deleted branches
  const modifierDeleteResult = await db.collection("modifier").deleteMany({
    branchId: { $in: branchIds },
  });

  return { restaurantDeleteResult, branchDeleteResult, modifierDeleteResult };
};
