import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { TablePagination, Switch } from "@mui/material";
import { useCuisines } from "./useCuisine";
import { MdDelete, MdEdit } from "react-icons/md";
import { BsDatabaseFillX } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Card from "components/card";

const Cuisines = () => {
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    onToggleActive,
    cuisinesData,
    status,
  } = useCuisines();

  const navigate = useNavigate();

  const handleAddCuisine = () => {
    navigate("/admin/add-cuisine");
  };

  const handleEditCuisine = (cuisineId: string) => {
    navigate(`/admin/edit-cuisine/${cuisineId}`);
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <div className="mb-10 flex justify-end lg:mb-0">
          <div className="relative mb-2 w-32">
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={handleAddCuisine}
            >
              Add Cuisine
            </button>
          </div>
        </div>

        <Card extra={"w-full h-full p-3"}>
          <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
            {status === "loading" ? (
              <p>Loading...</p>
            ) : status === "success" && cuisinesData?.data?.length !== 0 ? (
              <>
                <Table className="w-full min-w-full table-auto">
                  <Thead>
                    <Tr>
                      <Th className="border-blue-gray-50 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          SL No
                        </p>
                      </Th>
                      <Th className="border-blue-gray-50 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Name
                        </p>
                      </Th>
                      <Th className="border-blue-gray-50 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Description
                        </p>
                      </Th>
                      <Th className="border-blue-gray-50 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Is Active
                        </p>
                      </Th>
                      <Th className="border-blue-gray-50 border-b px-5 py-3 text-center">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Actions
                        </p>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {cuisinesData.data.map((cuisine: any, index: any) => (
                      <Tr key={cuisine._id}>
                        <Td className="border-blue-gray-50 border-b px-5 py-3">
                          <p className="text-left font-normal">{index + 1}</p>
                        </Td>
                        <Td className="border-blue-gray-50 border-b px-5 py-3">
                          <p className="font-normal">{cuisine.name}</p>
                        </Td>
                        <Td className="border-blue-gray-50 border-b px-5 py-3">
                          <p className="font-normal">
                            {cuisine.description || "N/A"}
                          </p>
                        </Td>
                        <Td className="border-blue-gray-50 border-b px-5 py-3">
                          <Switch
                            checked={cuisine.isActive}
                            onChange={() =>
                              onToggleActive(cuisine._id, !cuisine.isActive)
                            }
                          />
                        </Td>
                        <Td className="border-blue-gray-50 border-b px-5 py-3 text-center">
                          <div className="flex items-center gap-2 xl:justify-center">
                            <button
                              onClick={() => handleEditCuisine(cuisine._id)}
                              className="text-blue-500 hover:underline"
                            >
                              <MdEdit className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => onDelete(cuisine._id)}
                              className="text-red-500 hover:underline"
                            >
                              <MdDelete className="h-5 w-5" />
                            </button>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  className="text-sm font-bold text-navy-700 dark:text-white"
                  count={cuisinesData?.totalDocumentCount || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <div className="flex w-full flex-col items-center justify-center">
                <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
                <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Cuisines;
