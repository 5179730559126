import * as Realm from "realm-web";
import { createContext, useState, useEffect } from "react";
import { app } from "../services/dbServices";

export const UserContext = createContext(null);
export function UserProvider({ children }: any) {
  const [user, setUser] = useState<any>();
  const [customerData, setCustomerData] = useState();
  const isVerified = JSON.parse(localStorage.getItem("isVerified") || "false");
  const emailPasswordLogin = async (email: any, password: any) => {
    const credentials = Realm.Credentials.emailPassword(email, password);
    const authUser = await app.logIn(credentials);
    setUser(authUser);
    return authUser;
  };

  useEffect(() => {
    if (!user && app.currentUser) {
      setUser(app.currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app, app.currentUser]);

  const fetchUser = async () => {
    if (!app.currentUser) return false;
    try {
      await app.currentUser.refreshCustomData();
      setUser(app.currentUser);
      return app.currentUser;
    } catch (error) {
      throw error;
    }
  };

  // Function to logout user from our Realm
  const logOutUser = async () => {
    // if (!app.currentUser) return false;
    try {
      await app.currentUser.logOut();
      localStorage.setItem("isVerified", JSON.stringify(false));
      setUser(null);
      return true;
    } catch (error) {
      console.error("Error : ", error);
      throw error;
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        fetchUser,
        emailPasswordLogin,
        logOutUser,
        customerData,
        setCustomerData,
        isVerified,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
