import { Routes, Route, Navigate } from "react-router-dom";
import React, { lazy } from "react";
import { UserContext } from "controller/context/userContext";
import { QueryClient, QueryClientProvider } from "react-query";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";

const App = () => {
  const { user, isVerified } = React.useContext(UserContext);
  const queryClient = new QueryClient();
  const url = window.location.pathname;
  const AuthWithOTP = lazy(() => import("../src/views/auth/TwoStepAuth"));
  // Logging for debugging purposes

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        {/* Routes for authentication and admin layouts */}
        {/* <Route path="auth/*" element={<AuthLayout />} /> */}
        <Route
          path="auth/*"
          element={
            isVerified ? (
              // <ProtectedRoute path="/*" Component={AdminLayout} />
              <AdminLayout />
            ) : (
              <AuthLayout />
            )
          }
        />
        <Route
          path="admin/*"
          element={
            user && user.providerType === "local-userpass" && isVerified ? (
              // <ProtectedRoute path="/*" Component={AdminLayout} />
              <AdminLayout />
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        {/* Redirect logic based on user state */}
        <Route
          path="*"
          element={
            !user ? (
              <Navigate to="/auth" replace />
            ) : (
              <Navigate
                to={
                  url === "/" || url === "/auth/two-step-auth" ? "/admin" : url
                }
              />
            )
          }
        />

        {/* Root path handling */}
        <Route
          path="/"
          element={
            isVerified ? (
              <Navigate to="/admin" replace />
            ) : (
              <Navigate to="/auth" replace />
            )
          }
        />

        <Route
          path="/auth/two-step-auth"
          element={
            user && user.providerType === "local-userpass" && !isVerified ? (
              <AuthWithOTP />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </QueryClientProvider>
  );
};

export default App;
