export  const fetchedOptions = [
    { value: "", label: "Select a type" },
    { value: "veg", label: "Cafe" },
    { value: "nonVeg", label: "Restaurant" },
  ];

  export  const fetchedCategoryOptions = [
    { value: "Select a category", label: "Select a category" },
    { value: "burgers", label: "Burgers" },
    { value: "pizza", label: "Pizza" },
    { value: "chicken", label: "Chicken" },
  ];