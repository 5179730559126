import React, { useContext, useState, useRef } from "react";
import { AlertContext } from "controller/context/alertContext";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { updateUser } from "controller/services/userServices";
import {
  useUsersList,
  useRestaurantValues,
  useOneRestaurant,
} from "model/queryCalls/queryCalls";
import {
  AddRestaurantValues,
  deleteRestaurant,
} from "controller/services/restaurantServices";
import { FormValues } from "./restaurant.interface";
import { fetchedOptions } from "./dummyData";

export const useRestaurant = () => {
  const { setAlert } = useContext(AlertContext);
  const [restaurantId, setRestaurantId] = useState<string>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  console.log("restaurant id in useRestaurant=====", restaurantId);
  // States for logo
  const [brandImage, setBrandImage] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  // States for banner
  const [bannerImage, setBannerImage] = useState<string | null>(null);
  const [isBannerUploading, setIsBannerUploading] = useState<boolean>(false);
  const [address, setAddress] = useState<string | null>(null);

  const [isActive, setIsActive] = useState(true);
  const [branchId, setBranchId] = useState<string | null>(null);
  const [location, setLocation] = useState<{ lat: number; lng: number } | null>(
    null
  );

  const logoInputRef = useRef<HTMLInputElement>(null);
  const bannerInputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const { data: oneRestaurantData, refetch: restaurantRefetch } =
    useOneRestaurant({
      id: restaurantId,
    });

  console.log("oneRestaurantData======", oneRestaurantData);
  React.useEffect(() => {
    restaurantRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId]);

  React.useEffect(() => {
    const fetchOptions = () => {
      setOptions(fetchedOptions);
    };
    fetchOptions();
  }, []);

  const { data: restaurantData, status } = useRestaurantValues({
    page,
    rowsPerPage,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const { refetch } = useUsersList({ page, rowsPerPage });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const initialValues: FormValues = {
    name: "",
    description: "",
    type: "",
    isActive: true,
    category: "",
    location: "",
    contactDetails: "",
    username: "",
    password: "",
    paymentStatus: "",
    cuisine: "",
    restaurantIcon: null,
    restaurantBanner: null,
    workingHours: {
      sunday: { open: "", close: "" },
      monday: { open: "", close: "" },
      tuesday: { open: "", close: "" },
      wednesday: { open: "", close: "" },
      thursday: { open: "", close: "" },
      friday: { open: "", close: "" },
      saturday: { open: "", close: "" },
    },
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your restaurant name"),
    description: Yup.string().required("Please enter a description"),
    type: Yup.string().required("Please select your restaurant type"),
    contactDetails: Yup.string().required("Please enter your contact details"),
    username: Yup.string().required("Please enter a username"),
    password: Yup.string().required("Please enter a password"),
    paymentStatus: Yup.string().required("Please select a payment status"),
    cuisine: Yup.array().of(Yup.string()).required("Please select a cuisine"),

    // Working hours validation for each day
    // workingHours: Yup.object().shape({
    //   sunday: Yup.object({
    //     open: Yup.string().required("Sunday open time is required"),
    //     close: Yup.string().required("Sunday close time is required"),
    //   }),
    //   monday: Yup.object({
    //     open: Yup.string().required("Monday open time is required"),
    //     close: Yup.string().required("Monday close time is required"),
    //   }),
    //   tuesday: Yup.object({
    //     open: Yup.string().required("Tuesday open time is required"),
    //     close: Yup.string().required("Tuesday close time is required"),
    //   }),
    //   wednesday: Yup.object({
    //     open: Yup.string().required("Wednesday open time is required"),
    //     close: Yup.string().required("Wednesday close time is required"),
    //   }),
    //   thursday: Yup.object({
    //     open: Yup.string().required("Thursday open time is required"),
    //     close: Yup.string().required("Thursday close time is required"),
    //   }),
    //   friday: Yup.object({
    //     open: Yup.string().required("Friday open time is required"),
    //     close: Yup.string().required("Friday close time is required"),
    //   }),
    //   saturday: Yup.object({
    //     open: Yup.string().required("Saturday open time is required"),
    //     close: Yup.string().required("Saturday close time is required"),
    //   }),
    // }),
  });

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const restaurantData = {
        ...values,
        logo: brandImage,
        banner: bannerImage,
      };

      if (restaurantId) {
        // Update restaurant
        await updateUser({ restaurantId, data: restaurantData });
        setAlert({
          active: true,
          type: "success",
          message: "Restaurant Updated successfully",
        });
        setTimeout(() => {
          navigate("/admin/all-restaurants");
          setLoading(false);
        }, 1000);
      } else {
        // Add new restaurant
        const result = await AddRestaurantValues({
          data: restaurantData,
          location: location,
          address: address,
        });
        if (result) {
          setBranchId(result.insertedId);
          setAlert({
            active: true,
            type: "success",
            message: "Restaurant Created successfully",
          });
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to submit. Please try again.",
      });
    }
  };

  const onDelete = async (restaurantId: string) => {
    try {
      const result = await deleteRestaurant(restaurantId);

      if (
        result?.restaurantDeleteResult?.deletedCount > 0 &&
        result?.branchDeleteResult?.deletedCount > 0 &&
        result?.modifierDeleteResult?.deletedCount > 0
      ) {
        setAlert({
          active: true,
          type: "success",
          message: "Restaurant, branches, and modifiers successfully deleted",
        });
        refetch();
      } else if (
        result?.restaurantDeleteResult?.deletedCount > 0 &&
        result?.branchDeleteResult?.deletedCount > 0 &&
        result?.modifierDeleteResult?.deletedCount === 0
      ) {
        setAlert({
          active: true,
          type: "warning",
          message: "Restaurant and branches deleted, but no modifiers found",
        });
        refetch();
      } else {
        throw new Error("Failed to delete restaurant and related data");
      }
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete restaurant. Please try again.",
      });
    }
  };

  const onLogoSuccess = (res: any) => {
    setBrandImage(res.url);
    setIsUploading(false);
  };

  const onUploadProgress = () => {
    setIsUploading(true);
  };

  const handleDeleteLogoFile = () => {
    setBrandImage(null);
  };

  const onBannerSuccess = (res: any) => {
    setBannerImage(res.url);
    setIsBannerUploading(false);
  };

  const onBannerUploadProgress = () => {
    setIsBannerUploading(true);
  };

  const handleDeleteBannerFile = () => {
    setBannerImage(null);
  };

  return {
    page,
    rowsPerPage,
    navigate,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    loading,
    setLoading,
    initialValues,
    validationSchema,
    onSubmit,
    options,
    brandImage,
    isUploading,
    bannerImage,
    isBannerUploading,
    onLogoSuccess,
    onBannerSuccess,
    onUploadProgress,
    onBannerUploadProgress,
    handleDeleteLogoFile,
    handleDeleteBannerFile,
    isActive,
    setIsActive,
    logoInputRef,
    bannerInputRef,
    location,
    setLocation,
    branchId,
    restaurantData,
    status,
    setAddress,
    address,
    restaurantId,
    setRestaurantId,
  };
};
