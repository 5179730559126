import React, { useContext } from "react";
import { AlertContext } from "controller/context/alertContext";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  DeleteUser,
  updateUser,
} from "controller/services/userServices";
import {
  app,
} from "controller/services/dbServices";
import { useOneUser, useUsersList } from "model/queryCalls/queryCalls";
import { userRegister } from "controller/services/authServices";

export const useUsers = () => {
  const { id } = useParams();
  
  const { setAlert } = useContext(AlertContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const { data: userData, isSuccess } = useOneUser({ id });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const { refetch } = useUsersList({ page, rowsPerPage });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const onDelete = async (deleteId: any) => {
    try {
      await DeleteUser({ id: deleteId });
      refetch();
      setAlert({
        active: true,
        type: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete",
      });
    }
  };

  //add Users
  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
    password: !id
      ? Yup.string()
          .required("Please enter your password")
          .min(8, "Password must be at least 8 characters long")
          .matches(
            /[a-z]/,
            "Password must contain at least one lowercase letter"
          )
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase letter"
          )
          .matches(/[0-9]/, "Password must contain at least one number")
          .matches(
            /[@$!%*#?&]/,
            "Password must contain at least one special character (@, $, !, %, *, #, ?, &)"
          )
      : null,
    confirmPassword: !id
      ? Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Please confirm your password")
      : null,
    name: Yup.string().required("Please enter your name"),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      if (id) {
        await updateUser({ id, name: values.name });
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "User Updated successfully",
        });
        setTimeout(() => {
          navigate("/admin/all-users");
          setLoading(false);
        }, 1000);
      } else {
        await app.emailPasswordAuth.registerUser({
          email: values.email,
          password: values.password,
        });
        await userRegister({ data: values });
        setAlert({
          active: true,
          type: "success",
          message: "User Created successfully",
        });
        setTimeout(() => {
          navigate("/admin/all-users");
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      // loginError();
    }
  };

  return {
    page,
    rowsPerPage,
    navigate,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    //add user start
    loading,
    setLoading,
    initialValues,
    validationSchema,
    onSubmit,
    //edit user start
    id,
    userData,
    isSuccess,
  };
};
