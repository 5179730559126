import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "controller/context/alertContext";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { AddMenuValues } from "controller/services/menuServices";
import { useOneMenu, useMenuList } from "model/queryCalls/queryCalls";

interface Menu {
  _id: string;
  name: string;
  price: number;
  description: string;
  category: string;
  modifierIds: string[];
}

interface MenuResponse {
  data: Menu[];
  totalDocumentCount: number;
}

export const useMenu = () => {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [menuData, setMenuData] = useState<MenuResponse>({
    data: [],
    totalDocumentCount: 0,
  });
  const [initialValues, setInitialValues] = useState({
    name: "",
    price: 0,
    description: "",
    category: "",
    modifierIds: [],
  });

  const navigate = useNavigate();
  const {
    data: fetchedMenuData,
    isSuccess,
    status,
  } = useMenuList({
    page,
    rowsPerPage,
  });

  console.log("fetchedMenuData:", fetchedMenuData);

  useEffect(() => {
    if (isSuccess && fetchedMenuData) {
      setMenuData(fetchedMenuData);
    }
  }, [isSuccess, fetchedMenuData]);

  const { data: menuItemData, isLoading: isMenuLoading } = useOneMenu({ id });

  console.log("menuItemData:", menuItemData);

  useEffect(() => {
    if (id && menuItemData) {
      setInitialValues({
        name: menuItemData.name || "",
        price: menuItemData.price || 0,
        description: menuItemData.description || "",
        category: menuItemData.category || "",
        modifierIds: menuItemData.modifierIds || [],
      });
    }
  }, [id, menuItemData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter a menu name"),
    price: Yup.number()
      .required("Please enter a price")
      .positive("Price must be positive"),
    description: Yup.string().optional(),
    category: Yup.string().required("Please select a category"),
    modifierIds: Yup.array().of(Yup.string()).optional(),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      if (id) {
        setAlert({
          active: true,
          type: "success",
          message: "Menu item updated successfully",
        });
      } else {
        await AddMenuValues({ data: values });
        setAlert({
          active: true,
          type: "success",
          message: "Menu item created successfully",
        });
      }
      setTimeout(() => {
        navigate("/admin/all-restaurant");
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to save menu item",
      });
    }
  };

  return {
    page,
    rowsPerPage,
    navigate,
    handleChangePage,
    handleChangeRowsPerPage,
    menuData,
    loading,
    setLoading,
    initialValues,
    setInitialValues,
    validationSchema,
    onSubmit,
    id,
    menuItemData,
    isSuccess,
    status,
    isMenuLoading,
  };
};
