/*  */
/* Auth services */
/*  */

import { app } from "./dbServices";
import * as Realm from "realm-web";

export const userRegister = async ({ data }: any) => {
  if (!data) {
    return;
  }
  if (!app || !app.currentUser) {
    const credentials = Realm.Credentials.anonymous();
    await app.logIn(credentials);
  }
  const result = await app.currentUser.callFunction("register", {
    data,
  });

  return result;
};

export const sendEmailAfterLogin = async ({ toEmail }: any) => {
  if (!toEmail) {
    return;
  }

  const result = await app.currentUser.callFunction("reSendEmail", {
    toEmail,
  });
  return result;
};

export const verifyOTP = async ({ email, submittedOtp }: any) => {
  if (!email || !submittedOtp) {
    return;
  }

  const result = await app.currentUser.callFunction("verifyOTP", {
    email,
    submittedOtp,
  });
  return result;
};
