import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { AlertContext } from "controller/context/alertContext";
import { Alert, Snackbar } from "@mui/material";
import { app } from "controller/services/dbServices";

export default function ConformPassword() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const tokenId = params.get('tokenId');
  const [loading, setLoading] = React.useState(false);
  const { setAlert, active, type, message } = React.useContext(AlertContext);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter your password")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[@$!%*#?&]/,
        "Password must contain at least one special character (@, $, !, %, *, #, ?, &)"
      ),
    confirmPassword: Yup.string()
      .required("Please enter your confirm password")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[@$!%*#?&]/,
        "Password must contain at least one special character (@, $, !, %, *, #, ?, &)"
      ),
  });


  const onResetPassword = async (data: any) => {
    setLoading(true);
    try {
      if (data.password === data.confirmPassword) {
        await app.emailPasswordAuth.resetPassword({
            password: data.password,
            token,
            tokenId,
          });
          setLoading(false);
          setAlert({
            active: true,
            type: "success",
            message: "Password Reset Successful",
          });
          setTimeout(() => {
            navigate('/');
          }, 2000);
      } else {
        setLoading(false);
        setAlert({
            active: true,
            type: "error",
            message: "Incorrect Password",
            description: "The password you entered is incorrect. Please double-check your password and try again."
            });
      }
    } catch (error) {
      setLoading(false);
      console.log("error while login:", error);
      setAlert({
        active: true,
        type: "error",
        message: "Password Reset Error",
        description: "There was an issue in resetting password . Please try again later ."
      });
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Confirm Password?
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter a new password.
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onResetPassword}
        >
          <Form>
            {/* Password */}
            <div className="mb-3">
              <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                Password*
              </label>
              <Field
                type="password"
                name="password"
                className={
                  "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                }
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-500"
              />
            </div>
            <div className="mb-3">
              <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                Confirm Password*
              </label>
              <Field
                type="password"
                name="confirmPassword"
                className={
                  "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                }
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="text-red-500"
              />
            </div>
            <div className="mt-5 mb-5 text-sm text-gray-600 dark:text-gray-400">
              <p className="mb-3 text-navy-700 dark:text-white text-base">Password must contain:</p>
              <ul className="list-disc space-y-1 pl-5 mb-2 text-navy-700 dark:text-white">
                <li>At least 8 characters</li>
                <li>At least one uppercase letter (A-Z)</li>
                <li>At least one lowercase letter (a-z)</li>
                <li>At least one number (0-9)</li>
                <li>At least one special character (@, $, !, %, *, #, ?, &)</li>
              </ul>
            </div>
            {/* Checkbox */}
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              // onClick={() => navigate("/auth")}
              type="submit"
            >
              {loading ? "Loading..." : "Change Password"}
            </button>
          </Form>
        </Formik>

        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href="/auth/sign-up"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
      <Snackbar
        open={active}
        autoHideDuration={3000}
        onClose={() => {}}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
          severity={type}
          className="mx-3 my-3"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
