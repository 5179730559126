import { app, getDBInstance } from "./dbServices";

/* Order services */

export const getOrders = async ({ page = 0, rowsPerPage = 10 }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance()
    .collection("orders")
    .count({});

  const result = await getDBInstance()
    .collection("orders")
    .aggregate([{ $skip: skip }, { $limit: limit }]);

  return { data: result, totalDocumentCount };
};

// export const getOneCategory = async ({ id }: any) => {
//   if (!app || !app.currentUser) {
//     return;
//   }
//   if (!id) {
//     return;
//   }
//   if (typeof id === "string") {
//     id = new ObjectId(id);
//   }

//   const result = await getDBInstance().collection("categories").findOne({
//     _id: id,
//   });

//   return result;
// };
